html, body {
  height: 100%;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f0f0f0;
}
body a {
  text-decoration: none;
  color: #4274b8;
}

.pre-wrap-white-space {
  white-space: pre-wrap;
}

.ui-container .ui-frame {
  width: 100%;
  height: 100%;
}
.ui-container .ui-frame .ui-content {
  padding: 16px;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1067px) {
  .ui-container .ui-frame .ui-content {
    width: 734px;
    margin: 0 auto;
  }
}
@media (min-width: 1068px) {
  .ui-container .ui-frame {
    margin: 0 auto;
    width: 1024px;
  }
}

.font-largeTitle {
  font-size: 64px;
  line-height: 1.06;
  font-weight: 400;
  letter-spacing: -0.01em;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-subtitle {
  font-weight: 300;
  padding-bottom: 8px;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.016em;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-body {
  font-size: 20px;
  color: #303030;
  line-height: 1.1;
  font-weight: 300;
  letter-spacing: 0.016em;
  font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-callout {
  font-size: 16px;
  color: #303030;
  line-height: 1.1;
  font-weight: 300;
  letter-spacing: 0.046em;
  font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-title2 {
  font-size: 40px;
  padding-bottom: 10px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-title3 {
  font-size: 32px;
  padding-bottom: 10px;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: 0em;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-footnote {
  font-size: 12px;
  line-height: 1.4;
  font-weight: 300;
  letter-spacing: 0em;
  font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 1068px) {
  .font-largeTitle {
    font-size: 48px;
    line-height: 1.07;
    font-weight: 600;
    letter-spacing: -0.003em;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .font-subtitle {
    font-size: 19px;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0.012em;
    font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .font-body {
    letter-spacing: 0.018em;
  }

  .img-logo {
    width: 96px;
    height: 96px;
    margin: 9.6px;
  }

  .img-logo img {
    width: 96px;
  }

  .font-title2 {
    font-size: 32px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: 0.002em;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}
@media only screen and (max-width: 734px) {
  .font-largeTitle {
    font-size: 46px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: 0.004em;
    font-family: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .font-title2 {
    font-size: 28px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: 0.002em;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .font-body {
    letter-spacing: 0.018em;
  }

  .img-logo {
    width: 64px;
    height: 64px;
    margin: 6.4px;
  }

  .img-logo img {
    width: 64px;
  }

  .item-main {
    order: 1;
  }

  .item.side {
    order: 2;
  }

  .item-half {
    width: 100%;
    padding: 20px;
  }
}