@import './design.scss';
html, body { height: 100%; }
body {
    margin: 0;
    box-sizing: border-box;
    font-family: $dsgn-font-sans-serif;
    background-color: $dsgn-default-color-background;

    a {
        text-decoration: none;
        color: $dsgn-default-color-primary;
    }
}

.pre-wrap-white-space {
    white-space: pre-wrap;
}

.ui-container {
    .ui-frame {
        width: 100%;
        height: 100%;

        .ui-content {
             padding: $dsgn-default-padding;
             width: 100%;
             margin: 0 auto;
         }
         @include tablet {
             .ui-content {
                 width: $strc-tablet-frame-width;
                 margin: 0 auto;
             }
         }

         @include desktop {
             margin: 0 auto;
             width: $strc-desktop-frame-width;
         }
    }
}


.font-largeTitle {
    font-size: 64px;
    line-height: 1.06;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: $dsgn-font-sans-serif-title;
}

.font-subtitle {
font-weight: 300;
padding-bottom: 8px;
font-size: 22px;
line-height: 24px;
    // font-size: 21px;
    // line-height: 1.2;
    // font-weight: 400;
    letter-spacing: .016em;
    font-family: $dsgn-font-sans-serif-title;
}

.font-body {
    font-size: 20px;
    color: #303030;
    line-height: 1.1;
    font-weight: 300;
    letter-spacing: 0.016em;
    font-family: $dsgn-font-sans-serif;
}

.font-callout {
    font-size: 16px;
    color: #303030;
    line-height: 1.1;
    font-weight: 300;
    letter-spacing: 0.046em;
    font-family: $dsgn-font-sans-serif;
}


.font-title2 {
    font-size: 40px;
    padding-bottom: 10px;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 0em;
    font-family: $dsgn-font-sans-serif-title;
}

.font-title3 {
    font-size: 32px;
    padding-bottom: 10px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: 0em;
    font-family: $dsgn-font-sans-serif-title;
}


.font-footnote {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
    letter-spacing: 0em;
    font-family: $dsgn-font-sans-serif;
}

@media only screen and (max-width: 1068px) {
    .font-largeTitle {
        font-size: 48px;
        line-height: 1.07;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: $dsgn-font-sans-serif-title;
    }

    .font-subtitle {
        font-size: 19px;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: .012em;
        font-family: $dsgn-font-sans-serif;
    }
    .font-body {
        letter-spacing: 0.018em;
    }
    .img-logo {
        width: 96px;
        height: 96px;
        margin: 9.6px;
    }
    .img-logo img {
        width: 96px;
    }
    .font-title2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .002em;
        font-family: $dsgn-font-sans-serif-title;
    }

}

@media only screen and (max-width: 734px) {
    .font-largeTitle {
        font-size: 46px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: $dsgn-font-sans-serif;
    }

    .font-title2 {
        font-size: 28px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .002em;
        font-family: $dsgn-font-sans-serif-title;
    }

    .font-body {
        letter-spacing: 0.018em;
    }
    .img-logo {
        width: 64px;
        height: 64px;
        margin: 6.4px;
    }
    .img-logo img {
        width: 64px;
    }

    .item-main {
        order: 1;
    }
    .item.side {
        order: 2;
    }

    .item-half {
        width: 100%;
        padding: 20px;
    }
}
