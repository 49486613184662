

$strc-tablet-width: 768px;
$strc-desktop-width: 1068px;
$strc-tablet-frame-width: 734px;
$strc-desktop-frame-width: 1024px;

@mixin tablet {
  @media (min-width: #{$strc-tablet-width}) and (max-width: #{$strc-desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$strc-desktop-width}) {
    @content;
  }
}



// Colors
$dsgn-default-color-background: #f0f0f0;
$dsgn-default-color-primary: #4274b8;



//MARK: - fonts

$dsgn-font-sans-serif: "Source Sans Pro Regular", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial ,sans-serif;
$dsgn-font-sans-serif-title: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;


$dsgn-default-padding: 16px;
$dsgn-default-border: 1pt solid #333;
$dsgn-default-border-radius: 2pt;
